import { createFeature, createReducer, on } from "@ngrx/store";
import { userAction } from "./user.action";

export interface ValidateSubscription {
  isActive: boolean;
  plan: string;
}

export interface ValidateSubscriptionState {
  loading: boolean;
  error: string | null;
  data: ValidateSubscription;
}

const initialState: ValidateSubscriptionState = {
  loading: false,
  error: null,
  data: {
    isActive: true,
    plan: "Free",
  },
};

export const userFeature = createFeature({
  name: "user",
  reducer: createReducer(
    initialState,
    on(userAction.validateSubscription, (state) => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }),
    on(userAction.validateSubscriptionSuccess, (state, { data }) => {
      return {
        ...state,
        loading: false,
        error: null,
        data,
      };
    }),
    on(userAction.validateSubscriptionFailure, (state) => {
      return {
        ...state,
        loading: false,
        error: state.error,
      };
    })
  ),
});

export const { name, reducer, selectData } = userFeature;
